import React, { ReactElement, FC } from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../components/Layout/Layout";
import { PartialPost, PartialShowcase } from "../types/types";
import { Callout } from "../components/Callout/Callout";
import { SEO } from "../components/SEO/SEO";
import { ShowcaseCard } from "../components/Cards/ShowcaseCard";
import { PageHeader } from "../components/PageHeader/PageHeader";
import { CardCarousel } from "../components/CardCarousel/CardCarousel";

import { CardList } from "../components/CardList/CardList";

type ResourceCentrePageProps = PageProps<{
	prismicResourceCentre: {
		data: {
			title: string;
			subtitle: string;
			seo_title: string;
			seo_description: string;
		};
	};
	allPrismicPost: {
		nodes: PartialPost[];
	};
	allPrismicShowcase: {
		nodes: PartialShowcase[];
	};
}>;

const ResourceCentrePage: FC<ResourceCentrePageProps> = ({
	data: {
		prismicResourceCentre: {
			data: {
				title,
				subtitle,
				seo_title: seoTitle,
				seo_description: seoDescription,
			},
		},
		allPrismicPost: { nodes: resources },
		allPrismicShowcase: { nodes: showcases },
	},
}): ReactElement => {
	return (
		<Layout>
			<SEO title={seoTitle || title} description={seoDescription || subtitle} />
			<PageHeader heading={title} lead={subtitle} />

			<CardList
				ariaLabel="Resource articles"
				cards={resources}
				type="resource"
			/>

			<Callout />

			<CardCarousel
				heading="Play the showcase"
				cta="See all games"
				ctaLink="/showcase/"
			>
				{showcases.map((item) => {
					return <ShowcaseCard key={item.id} content={item} />;
				})}
			</CardCarousel>
		</Layout>
	);
};

export default ResourceCentrePage;

export const pageQuery = graphql`
	{
		prismicResourceCentre {
			data {
				title
				subtitle
				seo_title
				seo_description
			}
		}

		allPrismicPost(
			sort: {
				fields: [data___is_featured, first_publication_date]
				order: [ASC, DESC]
			}
		) {
			nodes {
				...PartialPost
			}
		}

		allPrismicShowcase(
			limit: 3
			sort: {
				fields: [data___is_featured, first_publication_date]
				order: [ASC, DESC]
			}
		) {
			nodes {
				...PartialShowcase
			}
		}
	}
`;
